/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
// withAuth.js
import React, { useEffect, useState } from "react";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  PublicClientApplication,
  InteractionStatus,
  EventType,
} from "@azure/msal-browser";
import { Button } from "antd"; // Assuming you're using Ant Design
import { LogOut } from "@styled-icons/ionicons-outline/LogOut";
import { ShowLoader } from "@pai";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_TENANT,
    redirectUri: process.env.REACT_APP_AUTH_RETURN_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ["User.Read"], // Scope you want to request access to
};

export const UserContext = React.createContext();

const initialState = {
  user: undefined,
  identity: undefined,
  loading: true,
  error: undefined,
  token: undefined,
  role: undefined,
  format: undefined,
  status: undefined,
};

const getPermissions = (role) => {
  let permissions = {};
  role.permissions.forEach((p) => {
    permissions[p.type] = permissions[p.type] || {};
    permissions[p.type][p.path] = permissions[p.type][p.path] || {};
    permissions[p.type][p.path][p.permission] = true;
  });
  return permissions;
};

const selectRole = (user, roleId) => {
  const allRoles = user.accesses;
  const role = allRoles.find((r) => r.role.id === roleId);
  return {
    role: role.role,
    permissions: getPermissions(role),
  };
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "identity":
      return { ...state, identity: action.payload };
    case "role":
      return { ...state, role: selectRole(state.user, action.payload) };
    case "user":
      return {
        ...state,
        user: action.payload.user,
        format: action.payload.format,
        status: action.payload.status,
        loading: false,
        error: undefined,
        token: action.payload.token,
        role: selectRole(action.payload.user, action.payload.user.accesses[0].role.id),
      };
    case "error":
      return { ...state, error: action.payload };
    case "logout":
      return { ...state, user: undefined };
    case "format":
      return { ...state, format: action.payload };
    default:
      return state;
  }
};

export const handleLogout = (instance) => {
  instance.logoutRedirect().catch((e) => {
    console.error("Logout error:", e);
  });
};

export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      type="link"
      icon={<LogOut height={20} width={20} />}
      onClick={() => handleLogout(instance)}
    >
      Logout
    </Button>
  );
};

const withMSALAuth = (WrappedComponent) => {
  return (props) => {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [state, dispatch] = React.useReducer(userReducer, initialState);

    useEffect(() => {
      const isADRequired = process.env.REACT_APP_IS_AD_REQUIRED === 'true';

      if (isADRequired && !isAuthenticated) {
        instance.loginPopup(loginRequest).catch((error) => {
          console.error("MSAL Login failed", error);
        });
      }
    }, [isAuthenticated, instance]);

    // If AD is required and the user is authenticated, proceed
    if (process.env.REACT_APP_IS_AD_REQUIRED === 'true') {
      if (isAuthenticated && accounts.length > 0) {
        const accountInfo = accounts[0];
        const username = accountInfo.username;

        return (
          <UserContext.Provider value={{ ...state, dispatch }}>
            <WrappedComponent {...props} accountInfo={accountInfo} />
          </UserContext.Provider>
        )
      }

      return ShowLoader({ message: "Almost there! Please wait ..." });
    }

    // If AD is not required, simply render the wrapped component
    return <WrappedComponent {...props} />;
  };
};

export default withMSALAuth;

