import React, { useState, createContext, useContext } from "react";
import ReactDOM from "react-dom";
import Axios from "axios";
import { Input, Button, Loading } from "@pai-ui/core";
import { ThemeProvider } from "@pai-ui/core/theme/theme-provider";
// import axios from "axios";
import { ConfigProvider as PaiUiConfigProvider } from "@pai-ui/core/config/config-provider";
import { WindowConfigProvider } from "./context/WindowContext";
import { MsalProvider } from "@azure/msal-react";
// import axiosInstance from "./core/index-core-nova";
// import { ThemeType } from '@pai-ui/core/theme/types';
import { useWindowConfig } from "@pai/context/WindowContext";
import { msalConfig } from "./providers/with-auth";
import { PublicClientApplication } from "@azure/msal-browser";

export const AppContext = createContext();
export const getAppContext = () => useContext(AppContext);

const App = React.lazy(() => import("./App.js"));

export const ShowLoader = ({ message = "Please wait ..." }) => {
  return (
    <div
      style={{
        zoom: 1.1,
        backgroundColor: "#0d0f13",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#181b20",
          borderRadius: 10,
          color: "grey",
          fontFamily: "sans-serif",
          padding: 12,
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Loading />
        <p>{message}</p>
      </div>
    </div>
  )
}

const AppProvider = () => {
  const [login, setLogin] = React.useState(false);
  const [criticalFontsLoaded, setCriticalFontsLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [isConfigUpdated, setIsConfigUpdated] = React.useState(false);
  const [proxyRedirection, setProxyRedirection] = React.useState({
    enabled: false,
    redirectTo: null
  });

  const { updateConfig } = useWindowConfig();

  const verifyPassword = (value) => {
    let allowedPasswords = process.env.REACT_APP_PASSWORD_VALUE;

    if (allowedPasswords === value) {
      localStorage.setItem("login", "true");
      setLogin(true);
      setError("");
    } else {
      setError('Invalid password!');
    }
  };

  const getMaintananceJson = async () => {
    const apidata = await Axios.get(`${process.env.REACT_APP_S3_BUCKET}/max_demos/maverick/maintenance.json`);

    if (apidata.data?.apps?.length) {
      const appNotificationObject = apidata.data?.apps?.find((x) => x?.url === window.location.origin);

      if (appNotificationObject) {
        if (appNotificationObject?.proxyRedirection?.enabled) {
          setProxyRedirection(appNotificationObject?.proxyRedirection)
        }

        updateConfig({ appNotificationObject });
      }
    }

    setIsConfigUpdated(true);
  };

  const RedirectProxy = () => {
    const isRedirectionEnabled = proxyRedirection?.enabled || false;
    const proxyUrl = proxyRedirection?.redirectTo || null

    if (isRedirectionEnabled && proxyUrl) {
      window.location.href = proxyUrl;
    }
  }

  React.useEffect(() => {
    getMaintananceJson();
  }, []);

  React.useEffect(() => {
    if (isConfigUpdated) {
      RedirectProxy();
    }
  }, [isConfigUpdated]);

  React.useEffect(() => {
    const isPasswordAuthRequired = process.env.REACT_APP_PASSWORD_PROTECTED || "false";

    if (isPasswordAuthRequired === "false") {
      setLogin(true);
    } else {
      const loginKeyExist = localStorage.getItem("login");

      if (loginKeyExist && loginKeyExist === "true") {
        setLogin(true);
      } else {
        localStorage.setItem("login", "false");
        setLogin(false);
      }
    }
  }, []);

  React.useEffect(() => {
    if (isConfigUpdated) {
      const fonts = {
        critical: [
          {
            family: "SF Display",
            weight: 200,
            src: "SF-UI-Display-Light.otf",
          },
          {
            family: "SF Display",
            weight: 400,
            src: "SF-UI-Display-Regular.otf",
          },
          {
            family: "SF Text",
            weight: 400,
            src: "SF-UI-Text-Regular.otf",
          },
          {
            family: "SF Text",
            weight: 200,
            src: "SF-UI-Text-Light.otf",
          },
        ],
        notCritical: [
          {
            family: "SF Display",
            weight: 700,
            src: "SF-UI-Display-Bold.otf",
          },
          {
            family: "SF Display",
            weight: 600,
            src: "SF-UI-Display-Semibold.otf",
          },
          {
            family: "SF Text",
            weight: 700,
            src: "SF-UI-Text-Bold.otf",
          },
          {
            family: "SF Text",
            weight: 700,
            style: "italic",
            src: "SF-UI-Text-BoldItalic.otf",
          },
          {
            family: "SF Text",
            weight: 200,
            style: "italic",
            src: "SF-UI-Text-LightItalic.otf",
          },
          {
            family: "SF Text",
            weight: 600,
            src: "SF-UI-Text-Semibold.otf",
          },
          {
            family: "SF Text",
            weight: 600,
            style: "italic",
            src: "SF-UI-Text-SemiboldItalic.otf",
          },
        ],
      };

      const loadAndAddFont = async (fontConfig) => {
        try {
          const font = new FontFace(
            fontConfig.family,
            `url(${process.env.REACT_APP_CLOUDFRONT_CDN}/fonts/${fontConfig.src})`,
            {
              weight: fontConfig.weight,
              style: fontConfig.style,
            }
          );

          await font.load();
          document.fonts.add(font);
        } catch (err) {
          console.error("ERROR_LOADING_FONTS", err, fontConfig);
        }
      };

      Promise.all(fonts.critical.map(loadAndAddFont)).then(() => {
        setCriticalFontsLoaded(true);
        const timeToWaitUntilAllCriticalAssetsAreLoaded = 500;

        setTimeout(() => {
          fonts.notCritical.forEach(loadAndAddFont);
        }, timeToWaitUntilAllCriticalAssetsAreLoaded);
      })
        .catch((err) => {
          console.error("ERROR_LOADING_FONTS Promise.all", err);
        });

      setLoading(false);
    }
  }, [isConfigUpdated]);

  React.useEffect(() => {
    if (criticalFontsLoaded) {
      setTimeout(() => {
        setLoading(true);
      }, 3000);
    }
  }, [criticalFontsLoaded]);

  if (!isConfigUpdated) {
    return ShowLoader({ message: "Fetching application settings ..." })
  }

  if (proxyRedirection?.enabled) {
    return ShowLoader({ message: "Redirecting..." })
  }

  if (!login) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "0px 40% 0px 40%",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            margin: "0",
            color: "hsla(0,0%,100%,.85)",
            fontSize: "14px",
            fontFamily: "SF Text",
            fontVariant: "tabular-nums",
            lineHeight: "1.5715",
            backgroundColor: "#111419",
          }}
        >
          {" "}
          Nova Dev is password protected.{" "}
        </div>
        <Input
          style={{
            background: "#2d2f31",
            height: "30px",
            border: "1px solid #3f4c58 !important",
            borderRadius: "5px",
            padding: "2px",
            width: "100%",
          }}
          type="password"
          placeholder="Password"
          id="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <div
          style={{
            margin: "0",
            color: "red",
            fontSize: "14px",
            fontFamily: "SF Text",
            fontVariant: "tabular-nums",
            lineHeight: "1.5715",
            backgroundColor: "#111419",
          }}
        >
          {" "}
          {error}{" "}{" "}
        </div>
        <Button
          label="Submit"
          style={{
            marginTop: "10px",
            display: "flex",
            float: "right",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => verifyPassword(password)}
        ></Button>
      </div>
    )
  }

  return (
    <App
      criticalFontsLoaded={criticalFontsLoaded}
      loading={loading}
      ShowLoader={ShowLoader}
    />
  );
};

const AuthenticateAndStartApp = () => {
  const [contextData, updateContextData] = useState({});

  const setContextData = (data) => {
    updateContextData({ ...contextData, ...data });
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <PaiUiConfigProvider
      visualization={{
        resizing: {
          eventName: "customResize",
          debounceMs: 500,
        },
      }}
      access={{
        secret: '6fCB49F95Cbb89CaC5F52D518c88df17',
        requestConfig: {
          payload: {
            client_name: 'panera',
            client_env: 'prod',
            product_name: 'p.ai'
          },
          headers: {
            'x-api-key': 'Eyh7cwF8hV2T5Pzq',
            "Content-Type": "application/json",
          }
        }
      }}
    >
      <ThemeProvider componentsStyles={
        {
          visualization: {
            root: {
              zoom: '1.1111111'
            }
          }
        }
      }
      >
        <React.Suspense fallback={ShowLoader({ message: "Gathering resources ..." })}>
          <AppContext.Provider value={{ contextData, setContextData }}>
            <WindowConfigProvider>
              <MsalProvider instance={msalInstance}>
                <AppProvider />
              </MsalProvider>
            </WindowConfigProvider>
          </AppContext.Provider>
        </React.Suspense>
      </ThemeProvider>
    </PaiUiConfigProvider>
  )
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<AuthenticateAndStartApp />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

